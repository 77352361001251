
function getServerDate() {
    try {
        const year = parseInt(document.getElementById('server-year').getAttribute('data-value'));
        const month = parseInt(document.getElementById('server-month').getAttribute('data-value'));
        const day = parseInt(document.getElementById('server-day').getAttribute('data-value'));

        return new Date(year, month - 1, day);
    } catch (e) {
        // as fallback use client time
        return new Date();
    }
}

function init() {
    let statutoryDeclarationVersion;
    const statutoryDeclarationVersionDiv = document.getElementById('study_year_declaration_version');

    if (statutoryDeclarationVersionDiv) {
        statutoryDeclarationVersion = statutoryDeclarationVersionDiv.value;
    }

    const learningTypeDiv = document.getElementById('learning-type');

    let learningType;
    if (learningTypeDiv) {
        learningType = learningTypeDiv.value;
    }

    if (statutoryDeclarationVersion === '2018') {
        import(/* webpackChunkName: "statutory-declaration-2018" */ './statutory-declaration-2018.js')
            .then(({ default: handle2018StatutoryDeclaration }) => {
                handle2018StatutoryDeclaration();
            });
    } else if (statutoryDeclarationVersion === '2019') {
        import(/* webpackChunkName: "statutory-declaration-2019" */ './statutory-declaration-2019.js')
            .then(({ default: handle2019StatutoryDeclaration }) => {
                window.serverDate = getServerDate();
                handle2019StatutoryDeclaration(learningType);
            });
    } else if (statutoryDeclarationVersion === '2020') {
        import(/* webpackChunkName: "statutory-declaration-2020" */ './statutory-declaration-2020.js')
            .then(({ default: handle2020StatutoryDeclaration }) => {
                window.serverDate = getServerDate();
                handle2020StatutoryDeclaration(learningType);
            });
    } else if (statutoryDeclarationVersion === '2021') {
        import(/* webpackChunkName: "statutory-declaration-2021" */ './statutory-declaration-2021.js')
            .then(({ default: handle2021StatutoryDeclaration }) => {
                window.serverDate = getServerDate();
                handle2021StatutoryDeclaration(learningType);
            });
    } else if (statutoryDeclarationVersion === '2022') {
        import(/* webpackChunkName: "statutory-declaration-2022" */ './statutory-declaration-2022.js')
            .then(({ default: handle2022StatutoryDeclaration }) => {
                window.serverDate = getServerDate();
                handle2022StatutoryDeclaration(learningType);
            });
    } else if (statutoryDeclarationVersion === '2023') {
        import(/* webpackChunkName: "statutory-declaration-2023" */ './statutory-declaration-2023.js')
            .then(({default: handle2023StatutoryDeclaration}) => {
                window.serverDate = getServerDate();
                handle2023StatutoryDeclaration(learningType);
            });
    } else if (statutoryDeclarationVersion === '2024') {
        import(/* webpackChunkName: "statutory-declaration-2024" */ './statutory-declaration-2024.js')
            .then(({ default: handle2024StatutoryDeclaration }) => {
                window.serverDate = getServerDate();
                handle2024StatutoryDeclaration(learningType);
            });

        import(/* webpackChunkName: "statutory-declaration-2024-ineligible" */ './statutory-declaration-2024-ineligible-info.js')
            .then(({ default: StatutoryDeclaration2024IneligibleInfoSetup }) => {
                StatutoryDeclaration2024IneligibleInfoSetup();
            });
    }
}

export default { init: init };
