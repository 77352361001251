export const getDataFromDOM = elementId => {
    // add {{ value|json_script:"element-id" }} to Django template, then pass element ID to this function to get data
    const scriptEl = document.getElementById(elementId);
    return scriptEl && JSON.parse(scriptEl.textContent);
};

export const getHtmlFromTemplateTag = elementId => {
    const templateEl = document.getElementById(elementId);
    const html = templateEl.content.cloneNode(true);
    return html;
    // document.body.appendChild(html);
};

export const loadScriptTag = (url, config = {}) => {
    let script = document.createElement('script');
    script.defer = true;
    script.src = url;

    script.onload = () => {
        config.onload && config.onload();
    };

    const documentHead = document.getElementsByTagName('head')[0];
    documentHead.appendChild(script);
};
