import select from 'dominus';

let menuIsOpen = false;

const _body = select('body');
const _html = select('html');

let _menuToggle;
let mainMobileMenuTrigger = document.getElementById('main-mobile-menu-trigger');

function toggleMobileMenu (e) {
    e.preventDefault();

    if (menuIsOpen) {
        // menu is open so close it
        _body.removeClass('mobile-menu--visible').addClass('mobile-menu--hidden');
        menuIsOpen = false;
        mainMobileMenuTrigger.style.display = "block";
        unlockBody();

    } else {
        _body.removeClass('mobile-menu--hidden').addClass('mobile-menu--visible');
        menuIsOpen = true;
        mainMobileMenuTrigger.style.display = "none";
        lockBody();
    }
}


function lockBody() {
    _body.addClass('overlay-open');
    _html.addClass('overlay-open');
}

function unlockBody() {
    _body.removeClass('overlay-open');
    _html.removeClass('overlay-open');
}

export function init(config) {
    _menuToggle = select(config.mobileMenuTrigger).on('click', toggleMobileMenu);
}

export default { init: init }
