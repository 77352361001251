// useful when event.target might be an svg
// intenet explorer delegates event inside the svg dom, not our dom. Internet explorer is a knob.
// this function helps us get the node we want.
//
// http://stackoverflow.com/questions/33672395/use-within-svg-not-finding-closest-div-in-ie

// usage
/*
    function itsACallback (e) {
        const target = getRealSVGTargetForIE(e.target);
        target.classList.add('clicked');
    }

    var thingWithSvg = select('.hasSVG');
    thingWithSvg.addEventListener('click', itsACallback);

    <p class="hasSVG">
        <svg width="10" height="10">
            <use xlink:href="#id-from-svg-blob"></use>
        </svg>
    </p>

*/
export default function getRealSVGTargetForIE(target) {
    return target.correspondingUseElement ? target.correspondingUseElement : target;
}
