import scrollTo from '../utils/scrollTo.js';

function init() {

    let criteriaTool = document.getElementById('criteria-tool');
    if (criteriaTool) {
        initTool();
    }

}

function initTool() {

    // Initialise on change handlers
    let inputs = document.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].type === 'radio') {
            inputs[i].addEventListener('change', showHideContent)
        }
    }

    showHideContent();

}

function showHideContent() {

    let defaultContent = document.getElementById('default-content');
    let contentBlocks = document.getElementsByClassName('eligibility-criteria');
    for (let i = 0; i < contentBlocks.length; i++) {
        contentBlocks[i].classList.add('u-hidden');
    }

    let inputs = document.getElementsByTagName('input');
    let slug = '';
    for (let i = 0; i < inputs.length; i++) {

        if (inputs[i].type === 'radio') {
            let radioParent = inputs[i].parentElement;
            radioParent.classList.remove('checked');
            if (inputs[i].checked) {
                slug += inputs[i].value + '-';
                radioParent.classList.add('checked');
            }
        }
    }

    slug = slug.substring(0, slug.length - 1);

    let content = document.getElementById(slug);
    if (content) {
        defaultContent.classList.add('u-hidden');
        content.classList.remove('u-hidden');
        scrollTo(content, -80, 800);
    }

}

export default { init: init }