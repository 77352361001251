function init() {

    let phoneCountrySelect = document.getElementById('id_phone_country');
    let callingCodePlaceholder = document.getElementById('country-calling-code');
    let addressCountrySelect = document.getElementById('id_country');

    if (phoneCountrySelect && callingCodePlaceholder) {
        phoneCountrySelect.onchange = function() {

            let selectedOption = phoneCountrySelect.options[phoneCountrySelect.selectedIndex];
            if (selectedOption) {

                if (selectedOption.value !== '') {
                    let countryCallingCode = selectedOption.getAttribute('data-calling-code');
                    if (countryCallingCode) {
                        callingCodePlaceholder.innerHTML = `+${countryCallingCode}`;
                    }
                    if (addressCountrySelect) {
                        addressCountrySelect.value = selectedOption.value;
                    }
                }
            }

        };

        phoneCountrySelect.onchange();


    }



}

export default { init: init }