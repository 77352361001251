import 'core-js/es/symbol';
import Map from 'core-js/es/map';

import debounce from '../utils/debounce';


export class BaseComponent {
    // TODO: now that we use a Map we should rename `i` in the various methods to the more semantically correct `key`.

    constructor(elem) {
        this.elem = elem;
        this.children = new Map(); // using a Map, so index positions can be left out
    }

    addChild(child) {
        let i = 0;
        while (this.children.get(i)) i++; // finding the first empty index
        this.children.set(i, child);
    }

    setChild(child, i = 0) {
        this.children.set(i, child);
    }

    getChild(i = 0) {
        return this.children.get(i);
    }

    getChildren() {
        return this.children;
    }

    showSelf() {
        this.elem.style.display = 'block';
    }

    showChild(i = 0) {
        const child = this.children.get(i);
        if (child && typeof child.showSelf === 'function') {
            child.showSelf();
        }
    }

    showChildren() {
        for (const child of this.children.values()) {
            if (typeof child.showSelf === 'function') {
                child.showSelf();
            }
        }
    }

    hideSelf() {
        this.elem.style.display = 'none';
    }

    hide(cascade = true) {
        this.hideSelf();
        if (cascade) {
            this.hideChildren();
        }
    }

    hideChildren(cascade = true) {
        // TODO: replace for with forEach.
        for (const child of this.children.values()) {
            child.hide(cascade);
        }
    }

    hideChild(i = 0, cascade = true) {
        const child = this.getChild(i);
        if (child && typeof child.hide === 'function') {
            child.hide(cascade);
        }
    }
}

export class InputComponent extends BaseComponent {
    constructor(elem) {
        super(elem);
        this.inputs = new Map(); // using a Map, so index positions can be left out
        this.inputCallbacks = new Map(); // using a Map, so index positions can be left out
        this.registerChangeHandlers();
    }

    setInput(input, i = 0) {
        this.inputs.set(i, input);
    }

    getInput(i = 0) {
        return this.inputs.get(i);
    }

    getInputs() {
        return this.inputs;
    }

    setInputCallback(callback, i = 0) {
        this.inputCallbacks.set(i, callback);
    }

    getInputCallback(i = 0) {
        return this.inputCallbacks.get(i);
    }

    registerChangeHandlers() {
        // implement in subclass
    }

    reset(cascade = true) {
        this.resetSelf();
        if (cascade) {
            this.resetChildren(cascade);
        }
    }

    resetSelf() {
        // implement in subclass
    }

    resetChildren(cascade = true) {
        for (const key of this.children.keys()) {
            this.resetChild(key, cascade);
        }
    }

    resetChild(i = 0, cascade = true) {
        const child = this.getChild(i);
        if (child && typeof child.reset === 'function') {
            child.reset(cascade);
        }
    }
}

export class SelectPicker extends InputComponent {
    constructor(elem) {
        super(elem);

        // the markup of a select element already defines it's options so use that
        [...this.elem.options].forEach((item, i) => {
            this.setInput(item, i);
        });

        this.initialOptionIndex = elem.selectedIndex; // todo: check this in ie.
    }

    resetSelf() {
        this.elem.selectedIndex = this.initialOptionIndex; // todo: check this in ie.
    }

    showSelectedChild() {
        // we derive the child key in the this.children mapping from the `value` of the selected option.
        const childKey = this.elem.children[this.elem.selectedIndex].value; // todo: check this in ie. re: selectedIndex
        this.showChild(parseInt(childKey, 10));

        // if can't use above in ie then use something like below.
        // this.inputs.forEach((input, index) => {
        //     if (input.selected) {
        //         this.showChild(index);
        //     }
        // });
    }

    registerChangeHandlers() {
        this.elem.addEventListener('change', () => {
            this.hideChildren();
            this.resetChildren();
            this.showSelectedChild();
        });
    }
}

export class DatePicker extends InputComponent {
    constructor(elem, yearSelect, monthSelect, daySelect) {
        super(elem);

        this.setInput(yearSelect, 0);
        this.setInput(monthSelect, 1);
        this.setInput(daySelect, 2);
    }

    resetSelf() {
        for (const input of this.inputs.values()) {
            input.value = '';
        }
    }

    year() {
        return this.inputs.get(0).value;
    }

    month() {
        return this.inputs.get(1).value;
    }

    day() {
        return this.inputs.get(2).value;
    }

    hasValidInput() {
        return this.inputs.get(0).selectedIndex && this.inputs.get(1).selectedIndex && this.inputs.get(2).selectedIndex;
    }

    hasNoValidInput() {
        return this.inputs.get(0).selectedIndex || this.inputs.get(1).selectedIndex || this.inputs.get(2).selectedIndex;
    }

    getValidInputCallback() {
        return this.getInputCallback(0);
    }

    getInvalidInputCallback() {
        return this.getInputCallback(1);
    }

    setValidInputCallback(callback) {
        this.setInputCallback(callback, 0);
    }

    setInvalidInputCallback(callback) {
        this.setInputCallback(callback, 1);
    }

    registerChangeHandlers() {
        this.elem.addEventListener('change', () => {
            this.hideChildren();
            this.resetChildren();

            if (this.hasValidInput()) {
                this.showChild(this.getChild());
                if (typeof this.getValidInputCallback() === 'function') {
                    this.getValidInputCallback()();
                }
            } else {
                if (typeof this.getInvalidInputCallback() === 'function') {
                    this.getInvalidInputCallback()();
                }
            }
        });
    }
}

export class RadioInputComponent extends InputComponent {
    inputChecked(i = 0) {
        const input = this.getInput(i);
        if (input) {
            return this.getInput(i).checked;
        }
        return false;
    }

    resetSelf() {
        for (const input of this.inputs.values()) {
            input.checked = false;
        }
    }

    registerChangeHandlers() {
        this.elem.addEventListener('change', (event) => {
            this.hideChildren();
            this.resetChildren();

            // proceeding logic assumes only one input can be checked at a time
            for (const key of this.inputs.keys()) {
                if (this.inputChecked(key)) {
                    this.showChild(key); // we assume that input and child share the same key

                    const callback = this.getInputCallback(key);
                    if (typeof callback === 'function') {
                        callback();
                    }
                }
            }
        });
    }
}

export class BooleanRadioInputComponent extends RadioInputComponent {

    noInputIndex = 0
    yesInputIndex = 1

    getYesChild() {
        return this.getChild(this.yesInputIndex);
    }

    getNoChild() {
        return this.getChild(this.noInputIndex);
    }

    setYesChild(child) {
        this.setChild(child, this.yesInputIndex);
    }

    setNoChild(child) {
        this.setChild(child, this.noInputIndex);
    }

    getYesInput() {
        return this.getInputs(this.yesInputIndex);
    }

    getNoInput() {
        return this.getInputs(this.noInputIndex);
    }

    setYesInput(input) {
        this.setInput(input, this.yesInputIndex);
    }

    setNoInput(input) {
        this.setInput(input, this.noInputIndex);
    }

    getYesCallback() {
        return this.getInputCallback(this.yesInputIndex);
    }

    getNoCallback() {
        return this.getInputCallback(this.noInputIndex);
    }

    setYesCallback(callback) {
        this.setInputCallback(callback, this.yesInputIndex);
    }

    setNoCallback(callback) {
        this.setInputCallback(callback, this.noInputIndex);
    }

    resetYesChild() {
        this.resetChild(this.yesInputIndex);
    }

    resetNoChild() {
        this.resetChild(this.noInputIndex);
    }

    showYesChild() {
        this.showChild(this.yesInputIndex);
    }

    showNoChild() {
        this.showChild(this.noInputIndex);
    }

    hideYesChild() {
        this.showChild(this.yesInputIndex);
    }

    hideNoChild() {
        this.showChild(this.noInputIndex);
    }
}

export class TextInputComponent extends InputComponent {
    resetSelf() {
        const inputs = this.getInputs();
        for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i];
            input.value = '';
        }
    }

    hasValidInput() {
        // override in subclass
    }

    hasNoValidInput() {
        // override in subclass
    }

    getValidInputCallback() {
        return this.getInputCallback(0);
    }

    getInvalidInputCallback() {
        return this.getInputCallback(1);
    }

    setValidInputCallback(callback) {
        this.setInputCallback(callback, 0);
    }

    setInvalidInputCallback(callback) {
        this.setInputCallback(callback, 1);
    }

    registerChangeHandlers() {
        const self = this;
        self.elem.addEventListener('input', debounce(
            (event) => {
                self.hideChildren();
                self.resetChildren();
                if (self.hasValidInput()) {
                    self.showChild();
                    if (typeof self.getValidInputCallback() === 'function') {
                        self.getValidInputCallback()();
                    }
                } else {
                    if (typeof self.getInvalidInputCallback() === 'function') {
                        self.getInvalidInputCallback()();
                    }
                }
            }, 500
        ));
    }
}

export class CheckboxInputComponent extends InputComponent {
    setInput(input) {
        this.inputs.set(0, input);
    }

    inputChecked() {
        const checkbox = this.inputs.get(0);
        if (checkbox) {
            return checkbox.checked;
        }
        return false;
    }

    resetSelf() {
        const checkbox = this.inputs.get(0);
        if (checkbox) {
            checkbox.checked = false;
        }
    }

    registerChangeHandlers() {
        this.elem.addEventListener('change', () => {
            if (this.inputChecked()) {
                this.showChildren();

                for (const callback of this.inputCallbacks.values()) {
                    if (typeof callback === 'function') {
                        callback();
                    }
                }
            } else {
                this.hideChildren();
                this.resetChildren();
            }
        });
    }
}
