import { BaseComponent, RadioInputComponent, TextInputComponent } from './form-objects'

function init() {

    let multiSelectForm = document.getElementById('multi-select-form');
    if (multiSelectForm) {
        initTool();
    }

}

class NsnNumberInput extends TextInputComponent {

    hasValidInput() {
        return this.inputs.get(0).value.length > 0
    }

    hasNoValidInput() {
        return this.inputs.get(0).value.length === 0;
    }

}

class LearningTypePicker extends RadioInputComponent {
    //
}

class LeftSchoolPicker extends RadioInputComponent {
    //
}

class FormSubmit extends BaseComponent {
    //
}

let nsnNumber;
let learningType;
let leftSchool;

let formSubmit;


function initTool() {

    nsnNumber = new NsnNumberInput(document.getElementById('nsn-number-input'));
    nsnNumber.setInput(document.querySelectorAll('[name="nsn_number"]')[0], 0);

    nsnNumber.showSelf();

    learningType = new LearningTypePicker(document.getElementById('learning-type-options'));
    learningType.setInput(document.querySelectorAll('[name="learning_type"]')[0], 0);
    learningType.setInput(document.querySelectorAll('[name="learning_type"]')[1], 1);

    leftSchool = new LeftSchoolPicker(document.getElementById('left-school-2017-plus-options'));
    leftSchool.setInput(document.querySelectorAll('[name="left_school_2017_plus"]')[0], 0);
    leftSchool.setInput(document.querySelectorAll('[name="left_school_2017_plus"]')[1], 1);

    formSubmit = new FormSubmit(document.getElementById('form-submit'));

    leftSchool.setChild(formSubmit, 0)
    leftSchool.setChild(formSubmit, 1)

    learningType.setChild(leftSchool, 0)
    learningType.setChild(leftSchool, 1)

    nsnNumber.setChild(learningType);

    setInitialComponentState();

}

function setInitialComponentState() {

    if ( nsnNumber.hasValidInput() ) {
        nsnNumber.showChild();
        if (learningType.inputChecked(0) || learningType.inputChecked(1)) {
            learningType.showChild();
            if (leftSchool.inputChecked(0) || leftSchool.inputChecked(1)) {
                leftSchool.showChild();
            }
        }
    }
}

export default { init: init }
