function init(config) {
    const acc = document.getElementsByClassName('accordion-trigger');
    if (acc) {
        for (let i = 0; i < acc.length; i++) {
            acc[i].onclick = function (e) {
                let svgArrow = this.getElementsByClassName('icon-accordion-arrow');
                let useTagSelectionEnabled = true;
                let svgArrowParent = null;
                if (svgArrow.length) {
                    svgArrowParent = svgArrow[0];
                    svgArrow = svgArrowParent.getElementsByTagName('use')[0];

                    // Support for IE 11
                    if (!svgArrow) {
                        svgArrow = document.createElementNS('http://www.w3.org/2000/svg', 'use');
                        useTagSelectionEnabled = false;
                    }
                } else {
                    svgArrow = null;
                }

                if (this.classList.contains(config.activeClass)) {
                    this.classList.remove(config.activeClass);
                    if (svgArrow && useTagSelectionEnabled) {
                        svgArrow.setAttribute('xlink:href', '#icon-closed-arrow');
                    } else if (svgArrow && !useTagSelectionEnabled && svgArrowParent) {
                        svgArrow.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '#icon-closed-arrow');
                        svgArrowParent.textContent = '';
                        svgArrowParent.appendChild(svgArrow);
                    }
                } else {
                    this.classList.add(config.activeClass);
                    if (svgArrow && useTagSelectionEnabled) {
                        svgArrow.setAttribute('xlink:href', '#icon-expanded-arrow');
                    } else if (svgArrow && !useTagSelectionEnabled && svgArrowParent) {
                        svgArrow.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '#icon-expanded-arrow');
                        svgArrowParent.textContent = '';
                        svgArrowParent.appendChild(svgArrow);
                    }
                }

                let panel = null;

                if (this.hasAttribute('data-target')) {
                    panel = document.getElementById(this.getAttribute('data-target'));
                } else {
                    panel = this.nextElementSibling;
                }

                if (panel.classList.contains(config.hiddenClass)) {
                    panel.classList.remove(config.hiddenClass);
                } else {
                    panel.classList.add(config.hiddenClass);
                }

                e.preventDefault();
            };
        }
    }
}

export default { init };
