import getRealSVGTargetForIE from '../utils/getRealSVGTargetForIE';
import GA from 'springload-analytics.js';

function handleTabs(tabBlock, activeClass) {

    // let tabElements = $(tabBlock).find('li');
    let tabElements= tabBlock.getElementsByTagName('li');

    let tabChangeEvent = new CustomEvent('tabChange');

    // Attach onclick handler to li
    if (tabElements.length) {

        for (let i = 0; i < tabElements.length; i++) {

            tabElements[i].addEventListener('click', (e) => {

                // Remove active class from tab elements
                for (let k = 0; k < tabElements.length; k++) {
                    tabElements[k].classList.remove(activeClass);
                }

                let target = getRealSVGTargetForIE(e.target);
                if (target.nodeName !== 'LI') {
                    target = target.parentNode;
                }
                target.classList.add(activeClass);
                let targetContainerId = target.getAttribute('data-target');

                // Hide inactive contains and show active ones.
                for (let k = 0; k < tabElements.length; k++) {
                    let tab = tabElements[k];
                    let containerTarget = document.getElementById(tab.getAttribute('data-target'));
                    if (containerTarget) {
                        if (targetContainerId !== containerTarget.id) {
                            containerTarget.style.display = "none";
                        } else {
                            containerTarget.style.display = "block";
                        }
                        document.dispatchEvent(tabChangeEvent);
                    }

                }


                // Push the browser history state.
                window.history.pushState(null, null, tabElements[i].getAttribute('data-url'));

                GA.track('Change tab page', 'Tab', 'Click');

                e.preventDefault();

            });

        }



    }

}


function init(config) {
    const tabBlock = document.getElementById(config.tabsId);
    if (tabBlock) {
        handleTabs(tabBlock, config.activeClass);
    }
}

export default { init: init }