// import external modules
import './polyfills';
import GA from 'springload-analytics.js';
import toggle from './modules/toggle-accordian.js';
import statutoryDeclarationForm from './modules/statutory-declaration-form';
import eligibilityMultiSelectForm from './modules/eligibility-multi-select-form.js';
import detailsForm from './modules/details-form.js';
import mobileMenu from './modules/mobile.menu.js'
import criteriaTool from './modules/eligibility-criteria-tool.js';
import tabs from './modules/tabs.js';

import { onPageReady } from './utils';
import { getDataFromDOM } from './utils/handleHtml';

const genesysConfig = getDataFromDOM('genesys-config') || {};

const config = Object.assign({
    GA: GA,
    header: '.page__header',
    tabsId: 'tabs',
    mobileMenu: '.mobile-menu',
    mobileMenuTrigger: '.mobile-menu-toggle',
    activeClass: 'active',
    hiddenClass: 'u-hidden',
}, window.APP_CONFIG);

function initialise() {
    GA.init();
    toggle.init(config);
    statutoryDeclarationForm.init();
    eligibilityMultiSelectForm.init();
    detailsForm.init();
    mobileMenu.init(config);
    criteriaTool.init(config);
    tabs.init(config);

    /* Review Request Form ----------------- */
    const reviewFormElem = document.getElementById('review-form');
    if (reviewFormElem) {
        import(/* webpackChunkName: "review-form" */ './modules/review-form.js')
            .then(({ default: reviewForm }) => {
                reviewForm();
            });
    }

    // import(/* webpackChunkName: "wagtail-glossary-admin" */ '../../app/wagtail_glossary/static_src/wagtail_glossary/js/wagtail-glossary.js');
    // import(/* webpackChunkName: "glossary-term-chooser-modal-admin" */ '../../app/wagtail_glossary/static_src/wagtail_glossary/js/glossary-term-chooser-modal.js');

    if (genesysConfig.chat_enabled) {
        import(/* webpackChunkName: "genesys-chat" */ './modules/genesys-chat')
            .then(({ default: initGenesysChat }) => {
                initGenesysChat();
            });
    }

    /*  Collapsibles  ------------------------ */
    const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
    if (collapsibleEls.length > 0) {
        import(/* webpackChunkName: "collapsible" */ './modules/collapsible.js')
            .then(({ default: collapsibleInit }) => {
                collapsibleInit([...collapsibleEls]);
            });
    }

    /*  Glossary items  --------------------- */
    const glossaryElms = document.querySelectorAll('[data-glossary-term-id]');
    if (glossaryElms.length > 0) {
        import(/* webpackChunkName: "glossary" */ './modules/glossary.js')
            .then(({ default: glossariesInit }) => {
                glossariesInit([...glossaryElms]);
            });
    }
}

/* Initialise app */
onPageReady(initialise);
